let widgetIdBase = '';
let widgetUrlBase = '';
let widgetTokenBase = '';
let widgetfontSizeButtonsExternalBase = '';
let languagePage = 'en';
let client_contact = { 'name': '', 'email': '', 'phone': '' };
// let agentsList;
// let agent_selected = null;
// let statusRegisterHistory = false;
// let progressRegisterHistory = 0;
// let messageWidgetAgent = '';
// let inputUser = '';
// let showListAgents = false;

window.addEventListener('DOMContentLoaded', function () {
    // initialModalWidget();

    // const buttonSubmit = document.getElementById('dialog-widget-area-actions-submit');
    // if (buttonSubmit)
    // buttonSubmit.addEventListener('click', submitMessageToHistory);

    getIdWidgetCaptureCustomer();

    getURlWidgetCaptureCustomer();

    getTokenWidgetCaptureCustomer();

    getFontSizeForButtonsExternalWidgetCaptureCustomer();

    getInfoForWidgetCustomerCaptureFromBusswe();

});

// Obtiene del html el lenguaje a usar en el widget.
function checkLanguageFromPage() {
    const inputLang = document.getElementById('dialog-widget-lang');

    if (inputLang) {
        if (inputLang.value == 'es')
            languagePage = 'es';

        else
            languagePage = 'en';
    }
}

/**
 * Cambia los textos por defecto en el idioma establecido en el widget.
 * @returns 
 */
function setLanguageInWidgetModal()
{
    const modal = document.getElementById("myModal-widget-customer-capture");

    if( !modal ) return;

    const title = modal.querySelector(".title-header-form");
    if( title )
    title.innerHTML = languagePage == 'en' ? 'Complete the form fields to subscribe' : 'Completar los campos del formulario para suscribirse';

    const nameLabel = modal.querySelector("#form-label-name-modal-widget");
    if( nameLabel )
    nameLabel.innerHTML = languagePage == 'en' ? 'First name and Last name' : 'Nombre y Apellido';

    // const emailLabel = modal.querySelector("#form-label-email-modal-widget");
    // if( emailLabel )
    // emailLabel.innerHTML = languagePage == 'en' ? 'Email' : 'Email';

    const phoneLabel = modal.querySelector("#form-label-phone-modal-widget");
    if( phoneLabel )
    phoneLabel.innerHTML = languagePage == 'en' ? 'Phone' : 'Teléfono';

    const buttonSubmit = modal.querySelector("#form-button-submit-modal-widget");
    if( buttonSubmit )
    buttonSubmit.innerHTML = languagePage == 'en' ? 'To subscribe' : 'Suscribirse';

    // Boton externo al widget
    const buttonOpenModal = document.getElementById("myBtn-widget-customer-capture");
    if( buttonOpenModal )
    buttonOpenModal.innerHTML = languagePage == 'en' ? 'To subscribe' : 'Suscribirse';

}

// Obtiene del html el id a la API del Widget
function getIdWidgetCaptureCustomer() {
    const widgetId = document.getElementById('dialog-widget-id');

    if (widgetId && (widgetId.value != 'id' || widgetId.value != '')) {
        widgetIdBase = widgetId.value;
        widgetId.remove();
    }
}

// Obtiene del html el url a la API del Widget
function getURlWidgetCaptureCustomer() {
    const url = document.getElementById('dialog-widget-url');

    if (url && (url.value != 'url' || url.value != '')) {
        widgetUrlBase = url.value;
        url.remove();
    }
}

// Obtiene del html el token para las peticiones a la API del Widget
function getTokenWidgetCaptureCustomer() {
    const token = document.getElementById('dialog-widget-token');

    if (token && (token.value != 'token' || token.value != '')) {
        widgetTokenBase = token.value;
        token.remove();
    }
}

/**
 * Obtiene del html el font size para los botones del Widget Capture Customer.
 */
function getFontSizeForButtonsExternalWidgetCaptureCustomer() {
    const fontSizeButtonsExternal = document.getElementById('font-size-buttons-widget-customer-capture-token');

    if (fontSizeButtonsExternal && (fontSizeButtonsExternal.value != 'fontSizeButtonsExternal' || fontSizeButtonsExternal.value != '')) {
        widgetfontSizeButtonsExternalBase = fontSizeButtonsExternal.value;
        fontSizeButtonsExternal.remove();
    }
}

// Inicializa el widget con los datos obtenidos
function initializeWidget(data) {

    checkAndRemoveExtraModals();

    moveMainModalWidgetToEndBody();

    const widgetModal = document.getElementById('myModal-widget-customer-capture');

    // Idioma del widget
    if (data.language != '')
        widgetModal.querySelector('#dialog-widget-lang').value = data.language;

    languagePage = widgetModal.querySelector('#dialog-widget-lang').value;

    setLanguageInWidgetModal();

    // Texto del boton que llama al modal
    if (data.texts.message_button != '' && document.getElementById('myBtn-widget-customer-capture') )
    {
        // widgetModal.querySelector('#botton-widget-text').innerHTML = data.texts.message_button;
        // document.getElementById('myBtn-widget-customer-capture').innerHTML = data.texts.message_button;

        const buttonsMains = document.getElementsByClassName('myBtn-widget-customer-capture-mains');

        if( buttonsMains && buttonsMains.length > 0 )
        for (let index = 0; index < buttonsMains.length; index++) {
            const element = buttonsMains[index];

            // Verificando que el boton no tenga la clase noChangeText para proceder a cambiar el texto.
            if( !element.classList.contains( "noChangeText" ) )
            element.innerHTML = data.texts.message_button;
        }
    }

    // Cambiando el Font Size de los botones
    if( widgetfontSizeButtonsExternalBase != '' )
    {
        const buttonsMains = document.getElementsByClassName('myBtn-widget-customer-capture-mains');

        if( buttonsMains && buttonsMains.length > 0 )
        for (let index = 0; index < buttonsMains.length; index++) {
            const element = buttonsMains[index];

            element.style.fontSize = widgetfontSizeButtonsExternalBase;
        }
    }

    // Texto del boton del modal que envia el formulario
    if (data.texts.message_button_submit != '' && widgetModal.querySelector('#form-button-submit-modal-widget') )
    widgetModal.querySelector('#form-button-submit-modal-widget').innerHTML = data.texts.message_button_submit;

    // Texto principal
    if (data.texts.title != '')
        widgetModal.querySelector('.title-header-form').innerHTML = data.texts.title;

    // Images
    // Logo
    // if (data.images.logo != '')
    //     widgetModal.querySelector('#dialog-widget-agents-header img').src = data.images.logo;

    // Banner
    // if (data.images.banner != '') {
    //     widgetModal.querySelector('#dialog-widget-agents-image img').src = data.images.banner;
    // } else {
    //     widgetModal.querySelector("#dialog-widget").classList.add('widgetWithoutBanner');
    // }


    // Colores
    // Color de fondo Modal
    if (data.colors.background_main != '')
        widgetModal.querySelector('.modal-content').style.backgroundColor = data.colors.background_main;

    // Color de las letras Modal
    if (data.colors.letter_main != '')
        widgetModal.querySelector('.modal-content').style.color = data.colors.letter_main;

    // Boton Color de fondo Botones
    if (data.colors.button_color_background != '') {
        // const items = widgetModal.querySelectorAll('.myButtons-widget-customer-capture');
        const items = document.getElementsByClassName('myButtons-widget-customer-capture');

        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            element.style.backgroundColor = data.colors.button_color_background;
        }
    }

    // Boton Color de las letras Botones
    if (data.colors.button_color_text != '') {
        Array.from(
            // widgetModal.querySelectorAll('.myButtons-widget-customer-capture')
            document.getElementsByClassName('myButtons-widget-customer-capture')
            ).map(function (item) {

            return item.style.color = data.colors.button_color_text;
        });
    }


    // Settings - Position
    if (data.settings.position != '') {
        const buttons = document.getElementsByClassName("container-btn-widget-customer-capture");

        const positionButton = data.settings.position == 0 ? 'left' : ( data.settings.position == 1 ? 'center' : 'right' );

        if( buttons && buttons.length > 0 )
        {
            for (let index = 0; index < buttons.length; index++) {
                const element = buttons[index];
                
                element.parentNode.style.textAlign = positionButton;
            }
        }

        // widgetModal.classList.add('setToRight');
    }


    // Mostrando el widget
    // widgetModal.classList.remove('widget-agent-init');
    initialModalWidget();

    // Settings - Show Widget Modal when DomLoad end
    if (data.settings.inital_show != 0)
    widgetModal.style.display = "block";
}

/**
 * Consulta en Busswe la información del Widget
 */
async function getInfoForWidgetCustomerCaptureFromBusswe() {
    const url = widgetUrlBase + 'v1/widget/customer_capture/'+widgetIdBase;

    const fetchWidgetBase = await fetchCustomerCaptureWidget('GET', url);

    // console.log('Resultado del fetch ', fetchWidgetBase);

    if (fetchWidgetBase.status) {
        // console.log('Paso el fetch', fetchWidgetBase.result);
        initializeWidget(fetchWidgetBase.result);
    }
    else
        console.log('Fetch Failed');
}

// Envia la información del usuario al agente
async function sendNewSubscriberCustomer() {
    const url = widgetUrlBase + 'v1/widget/customer_capture';

    const data = {
        'widget_id': widgetIdBase,
        'name': client_contact.name,
        'email': client_contact.email,
        'phone': client_contact.phone,
    };

    const fetchWidgetAgentPost = await fetchCustomerCaptureWidget('POST', url, data);

    // Verifica que el fecth este correcto
    if (fetchWidgetAgentPost.status) {
        // console.log('Paso el fetch', fetchWidgetAgentPost.result);

        // Restablece los valores del client
        // reset_client_contact();
        if( fetchWidgetAgentPost.result.file_status == true )
        {
            // downloadFileFromHost( fetchWidgetAgentPost.result.file_url, fetchWidgetAgentPost.result.file_name );
            // console.log( fetchWidgetAgentPost.result.file_url, fetchWidgetAgentPost.result.file_name );
            downloadURI( fetchWidgetAgentPost.result.file_url, fetchWidgetAgentPost.result.file_name );
        }
        
        // Verifica si hay que redireccionar a una url
        if( fetchWidgetAgentPost.result.url_redirect_status == true )
        {
            redirectToUrlFromWidget( fetchWidgetAgentPost.result.url_redirect );
        }

        closeModalWidgetCustomerCapture();

        cleanFieldsFormModalWidgetCustomerCapture();

        return true;
    }
    else {
        console.log('Fetch failed');

        // resetProgressHistoryByFailedValidation();

        return false;
    }
}

// Fetch a la API Widget.
async function fetchCustomerCaptureWidget(method = 'GET', url, data = {}) {
    let options = {
        method: method,
        mode: 'cors',
        headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + widgetTokenBase
        }
    };

    if (method == 'POST' && Object.keys(data).length !== 0) {
        options.body = JSON.stringify(data);
        options.headers['Content-Type'] = 'application/json';
    }

    const resultFetch = { 'status': false, 'result': '' };

    let response = await fetch(url, options)
        .catch(function (error) {
            console.log('Hubo un problema con la petición Fetch:' + error.message);
        });

    if (response.ok && response.status == 200) {
        let resultJson = await response.json();

        resultFetch.status = true;
        resultFetch.result = resultJson;
    } else {
        resultFetch.status = false;
        console.log('Hubo un problema con la petición Fetch:' + response.message);
    }

    return resultFetch;
}

// Formulario

/**
 * Procesamiento del formulario, cancelando el evento submit del form, valida los campos del formulario y si paso las validaciones
 * se envia la información a la API de Busswe.
 * @param {*} e 
 * @returns 
 */
function processFormAndSubmit( e )
{
    let resultValidation = false;

    // Detiene el envio del formulario.
    e.preventDefault();

    setFieldsFormInVariable();

    // Valida los campos del formulario.
    resultValidation = validateAllInputUser();

    // Verifica si no paso la validación si el resultado retornado es false.
    if( resultValidation == false )
    return;

    // Envia el formulario a Busswe.
    sendNewSubscriberCustomer();
    // alert( "enviando info a Busswe" );
}

function setFieldsFormInVariable()
{
    const name = document.getElementById('form-name-modal-widget');
    const email = document.getElementById('form-email-modal-widget');
    const phone = document.getElementById('form-phone-modal-widget');

    if( name && email && phone )
    {
        client_contact.name = removeExtraWhiteSpaceInString( name.value );
        client_contact.email = removeExtraWhiteSpaceInString( email.value );
        client_contact.phone = removeExtraWhiteSpaceInString( phone.value );
    }
}

// Validación en el maximo de caracteres permitidos
function validationMaxCharacters(typeInput, input) {
    let result = { 'status': true, 'message': '' };
    let limitMinCharacters = 1;
    let limitMaxCharacters = 45;

    // Estableciendo los limites de caracteres en base al tipo del input
    if (typeInput == 2) {
        limitMinCharacters = 5;
        limitMaxCharacters = 45;

    } else if (typeInput == 3) {
        limitMinCharacters = 9;
        limitMaxCharacters = 14;
    }

    // Validando los limites de caracteres en el input
    if (input.length < limitMinCharacters || input.length > limitMaxCharacters) {
        switch (languagePage) {
            case 'en':
                if (typeInput == 3)
                    result.message = "The phone number is not correct, please enter it again.";

                else
                    result.message = "Number of characters is not valid. Minimun " + limitMinCharacters + " and maximun " + limitMaxCharacters + ".";
                break;

            default:
                if (typeInput == 3)
                    result.message = "El número de teléfono no es correcto, ingréselo nuevamente.";

                else
                    result.message = "Cantidad de caracteres no es válido. Mínimo " + limitMinCharacters + " y maximo " + limitMaxCharacters + ".";
                break;
        }
        result.status = false;
        return result;
    }

    return result;
}

// Validación usando expresiones regulares
function validationRegex(type, value) {
    let result = { 'status': true, 'message': '' };

    if (type == 1 && /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]{1,45}\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]{1,45}$/.exec(value) === null) {
        switch (languagePage) {
            case 'en':
                result.message = "Must be a first and last name";

                break;

            default:
                result.message = "Debe ser un nombre y un apellido";

                break;
        }
        result.status = false;
        return result;
    }
    else if (type == 2 && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.exec(value) === null) {
        switch (languagePage) {
            case 'en':
                result.message = "Email format is not valid";

                break;

            default:
                result.message = "Formato del email no es valido";

                break;
        }
        result.status = false;
        return result;
    }
    else if (type == 3) {

        if (!checkStringForOnlyCharactersForPhoneFied(value)) {
            switch (languagePage) {
                case 'en':
                    // result.message = "Phone format is invalid";
                    result.message = "I need a valid phone number.";

                    break;

                default:
                    // result.message = "Formato del telefono no es valido";
                    result.message = "Necesito un número de teléfono válido.";

                    break;
            }
            result.status = false;
            return result;
        }

        if (/^(\+[0-9]{1,2})?[0-9]{9,11}$/.exec(value) === null) {
            switch (languagePage) {
                case 'en':
                    result.message = "Phone format is invalid";

                    break;

                default:
                    result.message = "Formato del telefono no es valido";

                    break;
            }
            result.status = false;
            return result;
        }
    }

    return result;
}

// Valida toda la información ingresa por el usuario
function validateAllInputUser() {
    let result = false;

    if (client_contact) {
        let resultValidation = [];
        const input = [
            { 'typeInput': 1, 'value': client_contact.name },
            { 'typeInput': 2, 'value': client_contact.email },
            { 'typeInput': 3, 'value': client_contact.phone },
        ];

        resetSpanTextErrorValidation();

        // Validando el name, email y phone
        for (let index = 0; index < input.length; index++) {
            const element = input[index];

            const resultInput = validateMessagesFromUser(element.typeInput, element.value);
            resultValidation.push(resultInput.status);

            if( resultInput.status == false )
            setTextErrorInSpanInForm( element.typeInput, resultInput.message );
        }

        // Verifica los resultados, si existe por lo menos un false se restablecera el progreso a casi el inicio
        if (resultValidation.includes(false)) {
            // resetProgressHistoryByFailedValidation();

        } 
        else 
        {
            // No existe ninungo false en los resultados.
            result = true;
        }
    }

    return result;
}

function validateMessagesFromUser( typeInput, input )
{
    let result = { 'status' : true, 'message' : '', 'value' : input };

    // Validaciones

    // El campo no puede estar vacio

    // No puede superar el maximo de caracteres
    let validationFromMaxCharacters = validationMaxCharacters( typeInput, input );
    if( !validationFromMaxCharacters.status )
    {
        result.status = false;
        result.message = validationFromMaxCharacters.message;
        return result;
    }

    // Debe manterner el formato esperado
    let validationFromRegex = validationRegex( typeInput, result.value );
    if( !validationFromRegex.status )
    {
        result.status = false;
        result.message = validationFromRegex.message;
        return result;
    }

    return result;
}

/**
 * Inserta el mensaje error de validación en el span en base al input.
 * @param {integer} typeInput 
 * @param {string} message 
 */
function setTextErrorInSpanInForm( typeInput, message = '' )
{
    const spansErrors = { 
        1: 'form-span-name-modal-widget',
        2: 'form-span-email-modal-widget',
        3: 'form-span-phone-modal-widget'
    };

    if( spansErrors[ typeInput ] != null )
    {
        const span = document.getElementById( spansErrors[ typeInput ] );

        if( span )
        span.innerHTML = message;
    }

}

/**
 * Vacia el contenido de todos los span error del formulario.
 */
function resetSpanTextErrorValidation()
{
    const spansErrors = [ 
        'form-span-name-modal-widget',
        'form-span-email-modal-widget',
        'form-span-phone-modal-widget'
    ];

    for (let index = 0; index < spansErrors.length; index++) {
        const element = spansErrors[index];
        
        const span = document.getElementById( element );

        if( span )
        span.innerHTML = '';
    }
}

// Función para remover los espacios extras
function removeExtraWhiteSpaceInString(string, removeBreakLine = false) {
    if (removeBreakLine)
        string = string.replace('\n', ' ');

    return string.replace(/ +(?= )/g, '').trim();
}

// Verifica si un string tiene los valores para el campo phone
function checkStringForOnlyCharactersForPhoneFied(str) {
    return /^[+0-9]*$/.test(str);
}

// Modal

/**
 * Inicializa los eventos del boton y el modal.
 * 
 */
function initialModalWidget()
{
    // Get the modal
    var modal = document.getElementById("myModal-widget-customer-capture");
    
    // Get the button that opens the modal
    // var btn = document.getElementById("myBtn");
    var btnAll = document.getElementsByClassName("myButtons-widget-customer-capture inPage");

    var btnAllOutModal = document.getElementsByClassName("myButtons-widget-customer-capture");
    
    // When the user clicks on the button, open the modal
    if( modal )
    {
        // Get the <span> element that closes the modal
        var span = modal.querySelector(".close-modal-widget-customer-capture");
    
        // Obteniendo el elemento form del modal.
        const form = modal.querySelector('#form-modal-widget');

        // When the user clicks on <span> (x), close the modal
        if( span )
        span.onclick = function () {
            modal.style.display = "none";
            document.body.style.overflow = '';
        }
        
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
                document.body.style.overflow = '';
            }
        }

        // Agrega la función en el evento submit del formulario.
        if( form )
        form.addEventListener('submit', processFormAndSubmit);

        if( btnAll && btnAll.length > 0 )
        {
            for (let index = 0; index < btnAll.length; index++) {
                const elementBtn = btnAll[index];
                
                // Eventos en los botones
                // Para mostrar el modal
                elementBtn.onclick = function () {
                    modal.style.display = "block";
                    document.body.style.overflow = 'hidden';
                }

            }
        }        
    
    }

    // Mostrando los botones ocultos
    if( btnAllOutModal && btnAllOutModal.length > 0 )
    {
        for (let index = 0; index < btnAllOutModal.length; index++) {
            const element = btnAllOutModal[index];
            
            element.style.display = 'inline';
        }
    }
}

function closeModalWidgetCustomerCapture()
{
    // Get the modal
    var modal = document.getElementById("myModal-widget-customer-capture");

    if( !modal ) return;

    modal.style.display = "none";
    document.body.style.overflow = '';
}

/**
 * Restablece el valor de los inputs del formulario a vacio.
 */
function cleanFieldsFormModalWidgetCustomerCapture()
{
    // Get the modal
    var modal = document.getElementById("myModal-widget-customer-capture");

    if( !modal ) return;

    // Obteniendo el elemento form del modal.
    const form = modal.querySelector('#form-modal-widget');

    if( !form ) return;

    form.reset();

}

/**
 * Revisa si hay más de un modal widget para eliminarlos excepto uno.
 */
function checkAndRemoveExtraModals()
{
    const widgetModal = document.getElementsByClassName("modals-widget-customer-capture");

    if( widgetModal && widgetModal.length > 1 )
    for (let index = 1; index < widgetModal.length; index++) {
        const element = widgetModal[index];
        
        element.remove();
    }
}

/**
 * Mueve el modal del Widget al final del tag body,
 * para evitar conflitos con otros elementos de la pagina.
 */
function moveMainModalWidgetToEndBody()
{
    const widgetModal = document.getElementById('myModal-widget-customer-capture');

    if( !widgetModal ) return;

    document.body.appendChild( widgetModal );
}

// Fin Modal

// function downloadFileFromHost( url, filename )
// {
//     const data = JSON.stringify(url);
//     const link = document.createElement('a');

//     link.setAttribute('href',  data );
//     link.setAttribute('download', filename);
//     link.style.display = 'none';

//     document.body.appendChild(link);

//     link.click();

//     document.body.removeChild(link);
// }

const downloadURI = (uri, name) => {
    // const link = document.createElement("a");
    // link.download = name;
    // link.href = uri;
    // link.target = '_blank';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(uri, "_blank");
}

function redirectToUrlFromWidget( url )
{
    if( url && url != "" )
    window.location.href = url;
}
